<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!hidden"
        :style="
          $vuetify.breakpoint.mdOnly
            ? 'width: 130px !important; color: #363533; height: 30px'
            : 'width: 170px !important; color: #363533; height: 30px'
        "
        elevation="0"
        v-on="on"
        outlined
        @click="expense = {}"
      >
        {{ $t("expenses.cash_output") }}
      </v-btn>
    </template>
    <v-card id="output">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{ $t("expenses.cash_output") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="cashOutput-form"
        @submit.prevent="validateForm('cashOutput-form')"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" md="7" class="py-0 my-2">
              <label v-text="$t('inventorys.type_use')" />
              <v-select
                v-model="expense.concepto"
                outlined
                dense
                hide-details
                v-validate="'required'"
                data-vv-name="concepto"
                class="icon"
                single-line
                :items="type"
                :error-messages="errors.collect('cashOutput-form.concepto')"
              >
              </v-select>
            </v-col>

            <v-col cols="12" md="5" class="py-0 my-2">
              <label>{{ $t("expenses.cantidad") }}</label>

              <v-text-field
                v-model="expense.cantidad"
                outlined
                dense
                min="0"
                :max="max"
                :placeholder="'Max valor: ' + max + '€'"
                type="number"
                v-on:keypress="isNumber($event)"
                data-vv-name="cantidad"
                :error-messages="errors.collect('cashOutput-form.cantidad')"
                v-validate="'required'"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col v-if="expense.concepto == 1" cols="12" class="py-0 my-2">
              <label>{{ $t("expenses.comment") }}</label>

              <v-text-field
                v-model="expense.comentario"
                outlined
                dense
                hint="EL maximo valor debe ser:   max"
                data-vv-name="comentario"
                :error-messages="errors.collect('cashOutput-form.comentario')"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialog = false"
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
            ><v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}</v-btn
          >
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CashOutput",
  props: ["hidden"],
  data() {
    return {
      date: null,
      dialog: false,
      expense: {},
      max: null,
      type: [
        {
          text: this.$t("expenses.cash_card"),
          value: 0,
        },
        {
          text: this.$t("expenses.other"),
          value: 1,
        },
      ],
    };
  },
  mounted() {
    this.date = new Date().toISOString().substr(0, 10);
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    ...mapActions("expenses", ["cashOutput"]),

    async validateForm(scope) {
      this.errors.clear();
      let form_val = await this.$validator.validateAll(scope);
      this.expense.data = this.date;
      this.expense.insert = true;
      if (form_val) {
        this.cashOutput(this.expense).then((data) => {
          this.dialog = false;
          this.$emit("update");
          this.$alert(this.$t("transactionADD_success"), "", "");
        });
      }

      return false;
    },
  },
};
</script>

<style lang="sass">
.cusList
  height: 30px
.v-list-item__icon
  margin-right: 5px !important

.cusList .v-icon, .cusList .svg-icon
  margin-top: -3px
  color: var(--v-gris1-lighten2)
#time
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer
  margin-top: 8px !important
#output
  label
    display: block
    height: 25px
  .close .v-btn
    top: 0
    right: 0px
    position: absolute
    .v-icon
      color: var(--v-primary-base)
      position: absolute
</style>